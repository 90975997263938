
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.transport-type {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  &__item {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    background: $esmp-input-background-color;
    color: $color-black-op-40;

    &:not(:last-child) {
      margin-right: 3px;
    }

    &:first-child {
      border-radius: 8px 0 0 8px;
    }
    &:last-child {
      border-radius: 0 8px 8px 0;
    }
    &--active {
      color: $color-primary-1-day;
    }
  }
}

.way-block {
  position: relative;
  &__switcher {
    position: absolute;
    top: 50%;
    left: calc(50% - 80px);
    z-index: 6;
    width: 18px;
    height: 14px;
    background-repeat: no-repeat;
    background-position: center;
    transform: translateY(-50%);

    &:hover {
      cursor: pointer;
    }
  }
}

