
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.adding-block {
  $parentClass: &;

  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: auto;

  &__icon {
    display: inline-block;
    color: $color-primary-1-day;
    border: 1px solid $color-primary-1-day;
    border-radius: 50%;
    overflow: hidden;
    &--rotated {
      transform: rotate(45deg)
    }
    ::v-deep .esmp-svg-icon {
      height: 18px;
      width: 18px;
    }
  }
  &__text {
    font-size: 16px;
    line-height: 16px;
    color: $color-primary-1-day;
    letter-spacing: 0;
    white-space: nowrap;
    transition: color 0.2s;
    &:not(:empty) {
      margin-left: 15px;
    }
  }

  &:hover {
    cursor: pointer;
    #{$parentClass}__text {
      color: $color-primary-1-day;
    }
  }
}
